<template>
  <div class="container mt-1">
    <div>
      <h4>
        <router-link :to="{ name: 'list-select' }">
          <i class="fas fa-chevron-circle-left fa-lg" />
        </router-link>
        รายละเอียดรายการ
      </h4>
    </div>
    <!-- ตัวเลือกหวย -->

    <div class="mb-2">
      <div class="header-bg-content2 text-center">
        <h1 class="text-white font-medium-3">
          เลขที่รายการ #{{ $route.params.ListId }}
        </h1>
        <b-row class="text-info">
          <b-col cols="6">
            <div>
              <p>ราคาที่แทงรวม</p>
              <p>
                {{
                  Commas(
                    ListDataHead.reduce(
                      (acc, ele) => acc + Number(ele.amount),
                      0,
                    ),
                  )
                }}
                ฿
              </p>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <p>ผลได้เสียทั้งสิ้น</p>
              <p>
                {{
                  ListDataHead.some(
                    el =>
                      el.status_result !== 'lose' && el.status_result !== 'win',
                  )
                    ? '-'
                    : Commas(
                      ListDataHead.reduce(
                        (acc, ele) =>
                          acc +
                          (ele.status_result === 'win'
                            ? Number(ele.amount) * Number(ele.win_rate)
                            : 0),
                        0,
                      ) -
                        ListDataHead.reduce(
                          (acc, ele) => acc + Number(ele.amount),
                          0,
                        ),
                    )
                }}
                ฿
              </p>
            </div>
          </b-col>
          <b-col
            v-if="
              ListData.some(
                el => el.status_result === 'waiting' && el.status !== 'cancle',
              )
            "
            cols="12"
          >
            <b-button
              v-if="MinuteRefund <= 15"
              variant=""
              block
              class="bn"
              @click="Refund($route.params.ListId)"
            >
              คืนโพยหวย
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <div>
      <b-overlay
        :show="show"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <b-icon-controller
              font-scale="3"
              animation="cylon"
            />
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <b-card no-body>
          <b-row>
            <b-col cols="2" />
            <b-col
              cols="10"
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-select
                v-model="status_result"
                :options="op_reward"
                size="sm"
                placeholder="สถานะ"
                class="mt-1 mb-1 mr-1"
                @change="ShowHistory()"
              />

              <b-form-select
                v-model="name_type"
                :options="op_type"
                size="sm"
                placeholder="ประเภทหวย"
                class="mt-1 mb-1 mr-1"
                @change="ShowHistory()"
              />
            </b-col>
          </b-row>
          <b-table
            v-if="ListData.length > 0"
            responsive
            small
            :items="ListData"
            :fields="fields"
            :tbody-tr-class="rowClass"
          >
            <template #cell(no)="data">
              {{ Commas(data.index + 1) }}
            </template>
            <template #cell(status_result)="data">
              {{
                data.item.status_result === 'win'
                  ? 'ถูกรางวัล'
                  : data.item.status_result === 'lose'
                    ? 'ไม่ถูกรางวัล'
                    : 'รอผล'
              }}
            </template>
            <template #cell(win_lose)="data">
              <p
                :style="`color: ${
                  data.item.status_result === 'win'
                    ? 'blue'
                    : data.item.status_result === 'lose'
                      ? 'red'
                      : 'black'
                }`"
              >
                {{
                  data.item.status_result === 'win' ||
                    data.item.status_result === 'lose'
                    ? Commas(
                      (data.item.status_result === 'win'
                        ? data.item.amount * data.item.win_rate
                        : 0) - data.item.amount,
                    )
                    : '-'
                }}
              </p>
            </template>
          </b-table>
          <h1
            v-else
            class="font-medium-3 hd_list text-center"
            style="color: #c25e11"
          >
            ไม่พบรายการแทง
          </h1>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormSelect,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormSelect,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      name_type: null,
      op_type: [
        { value: null, text: 'ทั้งหมด' },
        { value: '3ตัวบน', text: '3 ตัวบน' },
        { value: '3ตัวล่าง', text: '3 ตัวล่าง' },
        { value: '3ตัวโต๊ด', text: '3 ตัวโต๊ด' },
        { value: '2ตัวบน', text: '2 ตัวบน' },
        { value: '2ตัวล่าง', text: '2 ตัวล่าง' },
        { value: '2ตัวโต๊ด(บน)', text: '2 โต๊ด(บน)' },
        { value: 'วิ่งบน', text: 'วิ่งบน' },
        { value: 'วิ่งล่าง', text: 'วิ่งล่าง' },
      ],
      status_result: null,
      op_reward: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'waiting', text: 'รอผล' },
        { value: 'win', text: 'ถูกรางวัล' },
        { value: 'lose', text: 'ไม่ถูกรางวัล' },
      ],
      fields: [
        {
          key: 'no',
          label: '#',
        },
        {
          key: 'bet',
          label: 'เลข',
        },
        {
          key: 'name_type',
          label: 'ประเภท',
        },
        {
          key: 'amount',
          label: 'ยอดแทง',
          formatter: value => this.Commas(value),
        },
        {
          key: 'win_rate',
          label: 'จ่าย',
          formatter: value => this.Commas(value),
        },
        {
          key: 'result_bet',
          label: 'เลขที่ออก',
        },
        {
          key: 'win_lose',
          label: 'ผลได้เสีย',
        },
        {
          key: 'status_result',
          label: 'สถานะ',
          // variant: 'success',
        },
      ],
      items: [
        {
          no: '1',
          bet: '50',
          status_result: '999',
        },
      ],
      ListDataHead: [],
      ListData: [],
      MinuteRefund: 0,
      Interval: null,
      show: false,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  mounted() {
    this.ShowHistory()
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') {
        return
      }
      if (item.status_result === 'win') {
        // eslint-disable-next-line consistent-return
        return colorClass
      }
    },
    async ShowHistory() {
      this.show = true
      const params = {
        ListId: this.$route.params.ListId,
        status_result: this.status_result,
        name_type: this.name_type,
      }
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/history/ShowByListId',
          // 'https://api.ma5lotto.com/api/history/ShowByListId',
          { params },
        )
        this.show = false
        if (response && response.success === true) {
          this.ListData = response.Mes
          if (this.ListDataHead.length === 0) {
            this.ListDataHead = response.Mes
          }
          if (this.ListData.length > 0) {
            this.MinuteRefund = this.DiffMinute(this.ListData[0].created_at)
            this.Interval = setInterval(() => {
              this.MinuteRefund = this.DiffMinute(this.ListData[0].created_at)
            }, 60000)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    DiffMinute(DateTime) {
      const DateList = momenttz(DateTime).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Minutes = DateNow.diff(DateList, 'minutes')
      if (Minutes > 15) {
        clearInterval(this.Interval)
      }
      return Minutes
    },
    async Refund(ListID) {
      try {
        const Obj = {
          ListID,
        }
        const { data: response } = await this.$http.post(
          'https://api.ma5lotto.com/api/bet/refund',
          Obj,
        )
        if (response && response.success === true) {
          this.SwalMes(
            'success',
            'คืนโพยเรียบร้อย',
            `ยอดคืนโพยทั้งหมด ${this.Commas(response.Mes)} บาท`,
          )
          this.$router.push({ name: 'list-select' })
        }
      } catch (err) {
        this.SwalMes('error', 'คืนไม่สำเร็จ', err.response.data.Mes)
        this.$router.push({ name: 'list-select' })
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    SwalMes(Type, Title, Text) {
      this.$swal({
        icon: Type,
        title: Title,
        text: Text,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.bd_list {
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  background-color: rgb(255, 255, 255);
}
.hd_list {
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  background-color: rgb(206, 206, 206);
  border-bottom: 1px solid rgb(160, 160, 160);
}
.header-bg-content2 {
  background-image: url(/images/bg-header.svg);
  background-color: #fff;
  padding: 10px;
  background-size: cover;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
}
.btn-type3 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  border-radius: 25px;
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  border-radius: 25px;
  border: 0px;
}
.btnp:hover {
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-size: 200% auto;
  transition: 0.5s;
}

.active,
.btnp:focus {
  background-color: #fff;
  color: rgb(0, 0, 0);
}
.pander {
  font-size: 0.8rem;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(179, 0, 0);
  border-radius: 5px;
}
.bn {
  background-color: #000;
}
</style>
